export const licenses = [
  {
    title: "普通自動二輪車免許",
    date: "2015/7",
  },
  {
    title: "普通自動車運転免許",
    date: "2007/9",
  },
]
