export const certifications = [
  {
    title: "色彩検定 3 級",
    date: "2011/12",
  },
  {
    title: "応用情報技術者",
    date: "2011/12",
  },
]
