import styled from "styled-components"

export const Heading = styled.div`
  width: 600px;
  margin: 160px auto 0;
  padding: 0;
  font-size: 1.4rem;
  letter-spacing: -0.05rem;
  line-height: 1.3;
  text-align: center;
`

export const Title = styled.div`
  width: 600px;
  margin: 40px auto 0;
  padding: 0;
  font-size: 1.4rem;
  letter-spacing: -0.05rem;
  line-height: 1.3;

  ${Heading} + & {
    margin-top: 40px;
  }
`

export const Paragraph = styled.div`
  width: 600px;
  margin: 16px auto 0;

  ${Heading} + & {
    margin-top: 40px;
  }
`

export const Key = styled.div`
  text-align: left;
`

export const Value = styled.div`
  text-align: right;
`
