import React from "react"
import styled from "styled-components"
import { nl2br } from "../../../../util/nl2br"
import { personalWorks } from "../../../../contents/2021/6/26/personal-works"
import { Heading, Paragraph, Title } from "./Text"

const format = (dateString) => {
  const date = new Date(Date.parse(dateString))

  return [
    date.getFullYear().toString().padStart(4, "0"),
    "/",
    (date.getMonth() + 1).toString().padStart(2, "0"),
    "/",
    date.getDate().toString().padStart(2, "0"),
  ].join("")
}

export const PersonalWorks = () => (
  <>
    <Heading>個人制作物</Heading>
    <Paragraph>
      下記は業務とは別に個人として勉強または問題解決のために制作したものの一覧で、業務での経験はここには含まれていません。
    </Paragraph>
    {Object.entries(personalWorks).map(([key, value], index) => (
      <Wrapper key={index}>
        <Category>
          {key === "tool"
            ? "ツール"
            : key === "webSite"
            ? "Web サイト"
            : "練習"}
        </Category>
        <div>
          {value.map((w, index) => (
            <React.Fragment key={index}>
              <Name>
                {w.url !== "" ? (
                  <a href={w.url}>{w.name}</a>
                ) : (
                  <span>{w.name}</span>
                )}
              </Name>
              <StyledWork>
                <div>
                  <Description>{w.description}</Description>
                  <Comment>{nl2br(w.comment)}</Comment>
                  {w.links.length > 0 && (
                    <Links>
                      {w.links.map((l, index) => (
                        <li key={index}>
                          <a href={l}>{l}</a>
                        </li>
                      ))}
                    </Links>
                  )}
                </div>
                <div>
                  <MetaCommit>
                    <div>
                      {format(w.firstCommitDate)} - {format(w.lastCommitDate)}
                    </div>
                    <div>{w.commitCount} commits</div>
                  </MetaCommit>
                  <TechnologyStack>
                    {w.technologyStack.map((t, index) => (
                      <Technology key={index}>{t}</Technology>
                    ))}
                  </TechnologyStack>
                </div>
              </StyledWork>
            </React.Fragment>
          ))}
        </div>
      </Wrapper>
    ))}
  </>
)

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 920px;
  width: 1240px;
  margin: 16px auto 0;
  padding-left: 160px;

  ${Heading} + & {
    margin-top: 40px;
  }
`

const Category = styled(Title)`
  width: 160px;
`

const Name = styled(Title)`
  margin: 40px 0 0 0;
`

const StyledWork = styled.div`
  display: grid;
  grid-template-columns: 600px 320px;
  width: 920px;
`

const Description = styled.div`
  width: 600px;
  margin: 0 auto;
`

const Comment = styled.div`
  width: 600px;
  margin: 16px auto 0;
`

const MetaCommit = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
`

const TechnologyStack = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
`

const Technology = styled.li`
  margin-left: 16px;
`

const Links = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style: none;
`
