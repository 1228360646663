import React from "react"
import styled from "styled-components"
import { education } from "../../../../contents/2021/6/26/education"
import { Heading } from "./Text"

export const Education = () => (
  <>
    <Heading>学歴</Heading>
    {education.map((e, index) => (
      <React.Fragment key={index}>
        <StyledEducation>
          <School>{e.school}</School>
          <Period>{e.period}</Period>
          <Location>{e.location}</Location>
        </StyledEducation>
        <Subjects>
          {e.subjects.map((s, index) => (
            <Subject key={index}>- {s}</Subject>
          ))}
        </Subjects>
      </React.Fragment>
    ))}
  </>
)

const StyledEducation = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  width: 600px;
  margin: 16px auto 0;

  ${Heading} + & {
    margin-top: 40px;
  }
`

const School = styled.div`
  justify-self: start;
`

const Period = styled.div`
  justify-self: end;
  font-size: 0.8rem;
`

const Location = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  justify-self: end;
  font-size: 0.8rem;
`

const Subjects = styled.div`
  width: 600px;
  margin: -20px auto 0;
`

const Subject = styled.div`
  margin: 4px auto 0;
  font-size: 0.8rem;
`
