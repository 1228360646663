import React from "react"
import { nl2br } from "../../../../util/nl2br"
import { about } from "../../../../contents/2021/6/26/about"
import { Heading, Paragraph } from "./Text"

export const About = () => (
  <>
    <Heading>自己紹介</Heading>
    {about.map((p, index) => (
      <Paragraph key={index}>{nl2br(p)}</Paragraph>
    ))}
  </>
)
