export const skills = [
  {
    title: "経験言語",
    items: [
      "JavaScript",
      "TypeScript",
      "PHP",
      "Scala",
      "Python",
      "C#",
      "Kotlin",
      "Ruby",
      "Rust",
      "Go",
      "Java",
      "C++",
      "HTML",
      "CSS",
    ],
  },
  {
    title: "経験フレームワーク",
    items: [
      "Express",
      "Vue.js",
      "React",
      "Nuxt.js",
      "FuelPHP",
      "Ruby on Rails",
      "Ktor",
      "Django",
      "ASP.NET Core",
      "Akka HTTP",
    ],
  },
  {
    title: "その他",
    items: [
      "Node.js",
      "MySQL",
      "PostgreSQL",
      "Docker",
      "Vagrant",
      "Amazon Web Services",
      "Gatling",
      "New Relic",
      "GraphQL",
      "Ansible",
    ],
  },
]
