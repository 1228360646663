import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../../components/Layout"
import { Seo } from "../../../../components/Seo"
import { Heading } from "../../../../components/2021/6/26/Text"
import { Basic } from "../../../../components/2021/6/26/Basic"
import { Biography } from "../../../../components/2021/6/26/Biography"
import { About } from "../../../../components/2021/6/26/About"
import { Skills } from "../../../../components/2021/6/26/Skills"
import { Experience } from "../../../../components/2021/6/26/Experience"
import { Education } from "../../../../components/2021/6/26/Education"
import { Certifications } from "../../../../components/2021/6/26/Certifications"
import { Licenses } from "../../../../components/2021/6/26/Licenses"
import { PersonalWorks } from "../../../../components/2021/6/26/PersonalWorks"

const Page = ({
  data: {
    site: {
      siteMetadata: { title, description },
    },
  },
  location,
}) => (
  <Layout location={location}>
    <Seo
      title={title}
      description={description}
      slug={"/2021/6/26/"}
      article={false}
    />
    <Heading>職務経歴書</Heading>
    <Basic />
    <Biography />
    <About />
    <Experience />
    <Education />
    <Certifications />
    <Licenses />
    <Skills />
    <PersonalWorks />
  </Layout>
)
export default Page

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
