import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import { theme } from "../../../theme"

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sans-serif;
    font-size: 16px;
    font-kerning: normal; // フォントのカーニングを常に有効にする
    font-feature-settings: "pkna"; // 自動カーニングさせる
    letter-spacing: 0.03rem; // 字間を調整
    line-height: 1.55;
    -webkit-font-smoothing: antialiased; // フォントにアンチエイリアスをかける (少し細く見える)
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
    color: ${theme.color};
    background-color: ${theme.backgroundColor};
  }

  body {
    margin: 0;
  }

  a {
    color: ${theme.linkColor};
  }

  a:visited {
    color: ${theme.linkColor};
  }

  * {
    box-sizing: border-box;
  }
`

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Home to={"/"}>
      <HomeIcon>
        <path
          fill={theme.backgroundColor}
          stroke={theme.color}
          stroke-width="2px"
          d="
            M 2,14
            C 2,2 2,2 14,2
            S 26,2 26,14
              26,26 14,26
              2,26 2,14
          "
        />
      </HomeIcon>
    </Home>
    {children}
  </>
)

const Home = styled(Link)`
  display: block;
  width: min(600px, 90%);
  margin: 16px auto 0;
`

const HomeIcon = styled.svg`
  width: 28px;
  height: 28px;
`

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
