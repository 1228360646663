import React from "react"
import styled from "styled-components"
import { skills } from "../../../../contents/2021/6/26/skills"
import { Heading, Title } from "./Text"

export const Skills = () => (
  <>
    <Heading>スキル</Heading>
    {skills.map((c, index) => (
      <React.Fragment key={index}>
        <Title>{c.title}</Title>
        <Items>
          {c.items.map((i, index) => (
            <Item key={index}>{i}</Item>
          ))}
        </Items>
      </React.Fragment>
    ))}
  </>
)

const Items = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  width: 600px;
  margin: 16px auto 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  margin: 0;
`
