export const about = [
  `
  私は自己成長を続けるための行動理念として「Stably Unstable (安定的に不安定)」を掲げています。
  Stably Unstable とは、平衡が崩れた状態こそが変化や成長に必要な状況と考え、常に変化し続けるために継続的に平衡の崩れた状態を作り出すことです。
  このような考えで数名〜数十名規模の様々なチームに積極的に参加し、経験を積んでいます。
  チームの中ではチームとしての価値を最大化することを意識しながら自律的に動き、心理的安全性のために雑談できる環境づくりを提案するなどを行ってきました。
  リモートワークの経験も長く、オンライン上でのみで完結するフルリモートの経験もあり、GTD やポモドーロテクニックを使用して自己管理をしています。
  `,
  ` 
  開発は主にサーバーサイドの開発を行ってきましたが、フロントエンドの開発や負荷テスト、ボトルネックの調査を行って負荷対策を行った経験などもあります。
  いろいろな言語の経験があり、未経験の言語やツールなどを使うことになった場合も自ら学習し、使用できるようになってきました。
  継続的に勉強をしており、勉強会や競技プログラミングへの参加、自作ツールの作成などを行い、調べたことはブログに書くなど文書化することを意識しています。
  競技プログラミングのおかげで思考の幅が広がり、速度と精度を両立させたアルゴリズムで問題を改善した経験があります。
  `,
  ` 
  2019 年と 2020 年は海外に滞在していました。
  英語がまったく話せなかったので、まずはオーストラリアの語学学校で英語を勉強しました。
  はじめは一番下の Beginner クラスから始まったのですが、勉強に励み 4 ヶ月後の卒業時には 7 段階中 4 段階目の Intermediate クラスまで上がり、文法の成績は 6 段階目の Advanced 相当まで上がりました。
  その後はデンマークに行き、フォルケホイスコーレに 5 ヶ月通い Personal Leadership と Practical Sustainability について学びました。
  そこで学んだことのうち、特に瞑想を習慣化できたことが自分にとって大きな影響でした。
  `,
  `
  現在は自分用のタスク管理アプリを開発しながら、どんな仕事がしたいのかについて考えています。
  今は、教育の支援などを通して個々人の可能性が狭められないような社会の実現に少しでも貢献したいと思っています。
  `,
]
