import React from "react"
import { biography } from "../../../../contents/2021/6/26/biography"
import { Heading, Paragraph } from "./Text"

export const Biography = () => (
  <>
    <Heading>略歴</Heading>
    {biography.map((p, index) => (
      <Paragraph key={index}>{p}</Paragraph>
    ))}
  </>
)
