export const experience = [
  {
    company: "株式会社ミラボ",
    role: "Web Developer",
    period: "2017/4 - 2017/10",
    location: "Tokyo, Japan and Remote",
  },
  {
    company: "開業",
    role: "個人事業主",
    period: "2014/10 -",
    location: "",
  },
  {
    company: "株式会社フェローズ",
    role: "Web Developer",
    period: "2014/2 - 2014/10",
    location: "Sendai, Japan",
  },
  {
    company: "株式会社時空間",
    role: "Online Shop Assistant",
    period: "2013/6 - 2013/10",
    location: "Sendai, Japan",
  },
  {
    company: "株式会社アート・システム",
    role: "Developer",
    period: "2010/3 - 2013/5",
    location: "Sendai, Japan and Tokyo, Japan",
  },
]
