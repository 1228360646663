export const personalWorks = {
  tool: [
    {
      name: "export-personal-works",
      url: "https://github.com/5000164/export-personal-works",
      description:
        "GitHub のリポジトリをいつからどのくらい作っていたかをエクスポートする",
      comment: `
        どのリポジトリをいつからどのくらい作っていたかを知るために作成した。
        勉強を兼ねて Rust を使用した。
      `,
      firstCommitDate: "2021-05-30T08:48:53Z",
      lastCommitDate: "2021-06-26T06:57:31Z",
      commitCount: 6,
      technologyStack: ["Rust"],
      links: [],
    },
    {
      name: "base",
      url: "https://github.com/5000164/base",
      description: "タスク管理アプリ",
      comment: `
        自分のタスク管理手法に合わせて自分でカスタマイズできるタスク管理アプリが欲しくて開発している。
        後々データをサーバーに置くことになってもいいようにアプリ内で GraphQL を使用してフロントエンドとバックエンドを分けている。
      `,
      firstCommitDate: "2020-04-13T11:44:24Z",
      lastCommitDate: "2021-06-26T03:18:07Z",
      commitCount: 188,
      technologyStack: [
        "TypeScript",
        "GraphQL",
        "SQLite",
        "Prisma",
        "React",
        "Electron",
      ],
      links: [],
    },
    {
      name: "kpt",
      url: "https://github.com/5000164/kpt",
      description: "複数人で KPT を同時に行うためのアプリ",
      comment: `
        複数人でリアルタイムに反映される KPT のためのアプリが欲しかったので開発を開始した。
        未完成。
      `,
      firstCommitDate: "2019-02-04T12:13:05Z",
      lastCommitDate: "2020-04-05T17:36:15Z",
      commitCount: 126,
      technologyStack: [
        "Scala",
        "Scala.js",
        "Akka HTTP",
        "WebSocket",
        "Protocol Buffers",
        "React",
      ],
      links: [],
    },
    {
      name: "text-emoji-generator",
      url: "https://github.com/5000164/text-emoji-generator",
      description: "テキスト絵文字を生成するアプリ",
      comment: `
        Slack などで使用するテキスト絵文字を簡単に生成したかったので開発した。
      `,
      firstCommitDate: "2018-01-27T07:30:11Z",
      lastCommitDate: "2020-03-28T15:36:31Z",
      commitCount: 191,
      technologyStack: [
        "Scala",
        "Scala.js",
        "scalajs-react",
        "ScalaCSS",
        "ScalaTest",
        "Electron",
      ],
      links: ["https://blog.5000164.jp/2020/5/16/text-emoji-generator/"],
    },
    {
      name: "hatena-bookmark-filter",
      url: "https://github.com/5000164/hatena-bookmark-filter",
      description:
        "はてなブックマークの記事をフィルタリングして Slack に投稿するツール",
      comment: `
        はてなブックマークの記事をブックマーク数でフィルタリングしたかったので開発した。
      `,
      firstCommitDate: "2018-06-21T14:12:57Z",
      lastCommitDate: "2019-06-30T05:43:56Z",
      commitCount: 203,
      technologyStack: ["Scala", "Slick", "H2", "Flyway", "sttp", "ScalaTest"],
      links: ["https://blog.5000164.jp/2020/4/21/scala-flyway-slick/"],
    },
    {
      name: "pomodoro",
      url: "https://github.com/5000164/pomodoro",
      description: "ポモドーロテクニックのためのアプリ",
      comment: `
        自分のやり方に合わせたポモドーロテクニックのためのアプリが欲しかったので開発を開始した。
        未完成。
      `,
      firstCommitDate: "2019-04-14T10:24:18Z",
      lastCommitDate: "2019-04-15T06:03:31Z",
      commitCount: 5,
      technologyStack: ["Android"],
      links: [],
    },
    {
      name: "paper-invite-only",
      url: "https://github.com/5000164/paper-invite-only",
      description: "Dropbox Paper の閲覧範囲を招待した人だけにするツール",
      comment: `
        一括で設定を変更したかったので開発した。
      `,
      firstCommitDate: "2018-09-20T00:03:59Z",
      lastCommitDate: "2018-12-02T07:16:33Z",
      commitCount: 56,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "backlog-bot",
      url: "https://github.com/5000164/backlog-bot",
      description: "Backlog の更新を監視して Slack に通知するツール",
      comment: `
        Backlog の更新を Slack で受け取りたかったので開発した。
      `,
      firstCommitDate: "2018-02-16T14:16:35Z",
      lastCommitDate: "2018-09-26T05:43:01Z",
      commitCount: 121,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "statistics-experiment",
      url: "https://github.com/5000164/statistics-experiment",
      description: "実験を疑似体験するアプリ",
      comment: `
        実験を疑似体験することで理解が深まるかという仮説のために簡単なページを作成した。
      `,
      firstCommitDate: "2018-08-22T03:44:29Z",
      lastCommitDate: "2018-08-22T05:02:20Z",
      commitCount: 8,
      technologyStack: ["JavaScript", "GitHub Pages"],
      links: [],
    },
    {
      name: "timesheet-server",
      url: "https://github.com/5000164/timesheet-server",
      description: "Slack から出退勤を記録するツール",
      comment: `
        Slack から出退勤を記録するために開発を開始した。
        未完成。
      `,
      firstCommitDate: "2017-07-01T18:06:20Z",
      lastCommitDate: "2018-07-20T18:36:28Z",
      commitCount: 8,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "chat-range-exporter",
      url: "https://github.com/5000164/chat-range-exporter",
      description: "Slack のやり取りを範囲指定してエクスポートするツール",
      comment: `
        ある程度のやり取りをまとめてイシューなどに転記しておいたらわかりやすいと思って開発した。
      `,
      firstCommitDate: "2018-04-14T13:00:45Z",
      lastCommitDate: "2018-07-20T17:54:18Z",
      commitCount: 21,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "backlog-exporter-for-jira",
      url: "https://github.com/5000164/backlog-exporter-for-jira",
      description: "Backlog のコンテンツを JIRA 用にエクスポートするツール",
      comment: `
        JIRA にコンテンツを移行するために開発した。
      `,
      firstCommitDate: "2018-04-21T05:32:16Z",
      lastCommitDate: "2018-04-21T12:06:54Z",
      commitCount: 12,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "slack-emoji-generator-old",
      url: "https://github.com/5000164/slack-emoji-generator-old",
      description: "テキスト絵文字を生成するアプリ",
      comment: `
        絵文字を簡単に生成したかったので開発した。
        後に Electron に移行。
      `,
      firstCommitDate: "2018-01-08T07:39:01Z",
      lastCommitDate: "2018-01-26T14:34:06Z",
      commitCount: 29,
      technologyStack: ["Scala", "JavaFX"],
      links: [
        "https://blog.5000164.jp/2018/1/13/scala-javafx-image/",
        "https://blog.5000164.jp/2018/1/14/scala-javafx-generate-image/",
        "https://blog.5000164.jp/2018/1/15/scala-javafx-save-image/",
      ],
    },
    {
      name: "hugo-url-converter",
      url: "https://github.com/5000164/hugo-url-converter",
      description: "Hugo の URL を変更するツール",
      comment: `
        ブログの URL を一括で変更したかったので開発した。
      `,
      firstCommitDate: "2018-01-20T08:36:09Z",
      lastCommitDate: "2018-01-21T11:03:58Z",
      commitCount: 14,
      technologyStack: ["Scala"],
      links: ["https://blog.5000164.jp/2018/1/21/hugo/"],
    },
    {
      name: "scala-bot",
      url: "https://github.com/5000164/scala-bot",
      description: "Slack から Twitter に投稿するツール",
      comment: `
        Slack から Twitter に投稿するために開発した。
      `,
      firstCommitDate: "2017-10-29T08:11:08Z",
      lastCommitDate: "2017-11-29T04:51:49Z",
      commitCount: 24,
      technologyStack: ["Scala", "ScalaTest", "Mockito"],
      links: ["https://blog.5000164.jp/2017/11/29/slack-bot/"],
    },
    {
      name: "line-bot",
      url: "",
      description: "LINE 上で反応する bot",
      comment: `
        メモを登録しておいていつでも見れるようにしたいと思って開発を開始した。
        未完成。
      `,
      firstCommitDate: "2017-08-14T00:00:00Z",
      lastCommitDate: "2017-08-14T00:00:00Z",
      commitCount: 1,
      technologyStack: ["Python", "AWS Lambda", "Amazon API Gateway", "LINE"],
      links: ["https://blog.5000164.jp/2017/8/14/line-bot/"],
    },
    {
      name: "timesheet-client",
      url: "https://github.com/5000164/timesheet-client",
      description: "Slack から出退勤を記録するツール",
      comment: `
        Slack から出退勤を記録するために開発を開始した。
        未完成。
      `,
      firstCommitDate: "2017-07-01T19:43:03Z",
      lastCommitDate: "2017-07-07T12:45:21Z",
      commitCount: 4,
      technologyStack: ["Go"],
      links: [],
    },
    {
      name: "coubic-reserve-blocker",
      url: "https://github.com/5000164/coubic-reserve-blocker",
      description: "Coubic で指定した期間の予約をブロックするツール",
      comment: `
        Coubic で休憩時間が設定できなかったので開発した。
      `,
      firstCommitDate: "2015-11-20T19:37:16Z",
      lastCommitDate: "2016-05-14T22:36:39Z",
      commitCount: 9,
      technologyStack: ["JavaScript", "Chrome Extension"],
      links: ["https://blog.5000164.jp/2015/11/23/coubic/"],
    },
    {
      name: "kakeibo",
      url: "https://github.com/5000164/kakeibo",
      description: "家計簿アプリ",
      comment: `
        家計簿を自分用に作りたくて開発を開始した。
        未完成。
      `,
      firstCommitDate: "2014-02-01T15:28:24Z",
      lastCommitDate: "2015-12-04T19:26:37Z",
      commitCount: 16,
      technologyStack: ["Vagrant", "Ansible"],
      links: ["https://blog.5000164.jp/2015/11/28/ansible-sudo/"],
    },
    {
      name: "redirect-to-mobile-wikipedia",
      url: "https://github.com/5000164/redirect-to-mobile-wikipedia",
      description: "Wikipedia をモバイル版で表示するツール",
      comment: `
        モバイル版の方が見やすかったので開発した。
      `,
      firstCommitDate: "2015-03-22T14:14:20Z",
      lastCommitDate: "2015-03-22T15:51:53Z",
      commitCount: 5,
      technologyStack: ["TypeScript", "Chrome Extension"],
      links: [
        "https://blog.5000164.jp/2015/3/26/redirect-to-mobile-wikipedia/",
      ],
    },
    {
      name: "space-delete",
      url: "",
      description: "文章からスペースを削除するアプリ",
      comment: `
        アプリをインストールしてはいけない環境でスペースを簡単に削除できるようにするために開発した。
      `,
      firstCommitDate: "2013-10-23T00:00:00Z",
      lastCommitDate: "2013-11-29T00:00:00Z",
      commitCount: 4,
      technologyStack: ["HTML", "CSS", "JavaScript"],
      links: [
        "https://blog.5000164.jp/2013/10/23/space-delete/",
        "https://blog.5000164.jp/2013/11/29/space-delete/",
      ],
    },
  ],
  webSite: [
    {
      name: "blog.5000164.jp",
      url: "https://github.com/5000164/blog.5000164.jp",
      description: "ブログ",
      comment: `
        ブログの記事を Markdown で管理するため。
      `,
      firstCommitDate: "2017-08-12T00:58:16Z",
      lastCommitDate: "2021-06-26T05:43:05Z",
      commitCount: 385,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "gatsby-theme-blog",
      url: "https://github.com/5000164/gatsby-theme-blog",
      description: "ブログのテーマ",
      comment: `
        Gatsby 用のブログのテーマ。
      `,
      firstCommitDate: "2019-05-09T01:12:21Z",
      lastCommitDate: "2021-06-26T05:08:02Z",
      commitCount: 123,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "5000164.jp",
      url: "https://github.com/5000164/5000164.jp",
      description: "ポートフォリオ",
      comment: `
        ポートフォリオを Markdown で管理するため。
      `,
      firstCommitDate: "2017-10-31T12:30:41Z",
      lastCommitDate: "2021-06-04T02:22:44Z",
      commitCount: 114,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "blog.5000164.com",
      url: "https://github.com/5000164/blog.5000164.com",
      description: "ブログの英語版",
      comment: `
        英語で書くブログのドメインを分けるため。
      `,
      firstCommitDate: "2019-05-09T04:22:34Z",
      lastCommitDate: "2020-05-09T16:58:26Z",
      commitCount: 44,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "5000164.com",
      url: "https://github.com/5000164/5000164.com",
      description: "ポートフォリオの英語版",
      comment: `
        英語のポートフォリオのドメインを分けるため。
      `,
      firstCommitDate: "2019-05-08T23:25:34Z",
      lastCommitDate: "2020-03-09T19:17:07Z",
      commitCount: 23,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "remember",
      url: "https://github.com/5000164/remember",
      description: "ブログのテーマ",
      comment: `
        Hugo 用のブログのテーマ。
      `,
      firstCommitDate: "2017-08-12T14:54:31Z",
      lastCommitDate: "2019-04-20T15:36:16Z",
      commitCount: 82,
      technologyStack: ["Hugo"],
      links: [
        "https://blog.5000164.jp/2017/8/13/import/",
        "https://blog.5000164.jp/2017/8/14/syntax-highlighting/",
        "https://blog.5000164.jp/2017/11/26/speed/",
        "https://blog.5000164.jp/2018/8/25/hugo/",
      ],
    },
    {
      name: "study-english",
      url: "https://github.com/5000164/study-english",
      description: "英語勉強用のブログ",
      comment: `
        英語について勉強したことを記録に残すため。
      `,
      firstCommitDate: "2017-11-26T06:29:57Z",
      lastCommitDate: "2019-01-19T03:03:51Z",
      commitCount: 102,
      technologyStack: ["Gatsby"],
      links: [],
    },
    {
      name: "vertical",
      url: "https://github.com/5000164/vertical",
      description: "ブログのテーマ",
      comment: `
        Octopress 用のブログのテーマ
      `,
      firstCommitDate: "2014-05-20T07:47:52Z",
      lastCommitDate: "2015-12-06T17:41:36Z",
      commitCount: 10,
      technologyStack: ["Octopress"],
      links: ["https://blog.5000164.jp/2014/5/13/blog-moved/"],
    },
    {
      name: "WordPress",
      url: "",
      description: "ブログ",
      comment: `
        WordPress で作成したブログ。
      `,
      firstCommitDate: "2012-06-24T00:00:00Z",
      lastCommitDate: "2012-06-29T00:00:00Z",
      commitCount: 3,
      technologyStack: ["PHP", "HTML", "CSS", "JavaScript", "WordPress", "VPS"],
      links: [
        "https://blog.5000164.jp/2012/6/24/start-wordpress/",
        "https://blog.5000164.jp/2012/6/26/wordpress-twentyeleven-display-none/",
        "https://blog.5000164.jp/2012/6/29/wordpress-twentyeleven-edit/",
      ],
    },
  ],
  practice: [
    {
      name: "atcoder",
      url: "https://github.com/5000164/atcoder",
      description: "AtCoder 解答用リポジトリ",
      comment: `
        競技プログラミングを通してアルゴリズムの勉強をするため。
      `,
      firstCommitDate: "2019-04-29T11:01:38Z",
      lastCommitDate: "2021-01-20T02:09:40Z",
      commitCount: 73,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "dialog",
      url: "https://github.com/5000164/dialog",
      description: "対話アプリ",
      comment: `
        Ruby on Rails を練習するため。
      `,
      firstCommitDate: "2020-11-17T04:48:01Z",
      lastCommitDate: "2021-01-11T13:04:03Z",
      commitCount: 17,
      technologyStack: [
        "Ruby",
        "Ruby on Rails",
        "OpenAPI Specification",
        "PostgreSQL",
        "Docker",
      ],
      links: [],
    },
    {
      name: "practice",
      url: "https://github.com/5000164/practice",
      description: "練習用リポジトリ",
      comment: `
        練習用のリポジトリをまとめている。
        Scala.js、Node.js、Ktor の動かし方を練習した。
      `,
      firstCommitDate: "2019-04-20T03:22:07Z",
      lastCommitDate: "2020-06-06T15:12:56Z",
      commitCount: 25,
      technologyStack: [
        "Scala",
        "Scala.js",
        "JavaScript",
        "Node.js",
        "Kotlin",
        "Ktor",
      ],
      links: [],
    },
    {
      name: "make-a-lisp",
      url: "https://github.com/5000164/make-a-lisp",
      description: "Lisp を作る",
      comment: `
        Lisp を作りながら言語について学ぶため。
        https://github.com/kanaka/mal を参考に進めている。
      `,
      firstCommitDate: "2018-09-26T09:36:17Z",
      lastCommitDate: "2019-02-02T13:41:57Z",
      commitCount: 73,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "php7-practice-1",
      url: "https://github.com/5000164/php7-practice-1",
      description: "PHP 練習用リポジトリ",
      comment: `
        PHP 7 の練習をした。
      `,
      firstCommitDate: "2018-01-28T11:00:57Z",
      lastCommitDate: "2018-09-05T09:05:32Z",
      commitCount: 49,
      technologyStack: ["PHP", "Docker"],
      links: [],
    },
    {
      name: "scala-practice-1",
      url: "https://github.com/5000164/scala-practice-1",
      description: "Scala 練習用リポジトリ",
      comment: `
        Scala の使い方について調べたことをまとめた。
      `,
      firstCommitDate: "2017-12-08T13:51:58Z",
      lastCommitDate: "2018-04-12T13:09:24Z",
      commitCount: 75,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "s-99",
      url: "https://github.com/5000164/s-99",
      description: "S-99 解答用リポジトリ",
      comment: `
        S-99 を解きながら Scala について学ぶため。
        http://aperiodic.net/phil/scala/s-99/ を参考に進めている。
      `,
      firstCommitDate: "2017-12-28T11:23:17Z",
      lastCommitDate: "2018-01-03T14:38:22Z",
      commitCount: 18,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "vuejs-practice-1",
      url: "https://github.com/5000164/vuejs-practice-1",
      description: "Vue.js 練習用リポジトリ",
      comment: `
        Todo アプリを作って Vue.js の練習をした。
      `,
      firstCommitDate: "2017-07-27T10:07:38Z",
      lastCommitDate: "2017-08-03T07:57:51Z",
      commitCount: 28,
      technologyStack: ["JavaScript", "Vue.js"],
      links: [],
    },
    {
      name: "libgdx-othello",
      url: "https://github.com/5000164/libgdx-othello",
      description: "libGDX 練習用リポジトリ",
      comment: `
        オセロを作って libGDX の練習をした。
      `,
      firstCommitDate: "2017-02-13T15:53:57Z",
      lastCommitDate: "2017-03-26T18:41:50Z",
      commitCount: 51,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "libgdx-fizzbuzz-sin",
      url: "https://github.com/5000164/libgdx-fizzbuzz-sin",
      description: "libGDX 練習用リポジトリ",
      comment: `
        FizzBuzz をビジュアライズして libGDX の練習をした。
      `,
      firstCommitDate: "2017-02-15T14:41:21Z",
      lastCommitDate: "2017-02-18T14:46:51Z",
      commitCount: 10,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "libgdx-shape",
      url: "https://github.com/5000164/libgdx-shape",
      description: "libGDX 練習用リポジトリ",
      comment: `
        図形を描画して libGDX の練習をした。
      `,
      firstCommitDate: "2017-02-14T16:04:47Z",
      lastCommitDate: "2017-02-14T17:26:38Z",
      commitCount: 5,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "libgdx-fizzbuzz",
      url: "https://github.com/5000164/libgdx-fizzbuzz",
      description: "libGDX 練習用リポジトリ",
      comment: `
        FizzBuzz で libGDX の練習をした。
      `,
      firstCommitDate: "2017-02-10T16:10:30Z",
      lastCommitDate: "2017-02-12T15:23:01Z",
      commitCount: 15,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "libgdx-pyramid",
      url: "https://github.com/5000164/libgdx-pyramid",
      description: "libGDX 練習用リポジトリ",
      comment: `
        ピラミッドを描画して libGDX の練習をした。
      `,
      firstCommitDate: "2017-02-07T16:53:28Z",
      lastCommitDate: "2017-02-08T17:14:35Z",
      commitCount: 6,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "libgdx-test",
      url: "https://github.com/5000164/libgdx-test",
      description: "libGDX 練習用リポジトリ",
      comment: `
        libGDX の起動方法を練習した。
      `,
      firstCommitDate: "2017-02-02T16:48:36Z",
      lastCommitDate: "2017-02-02T17:27:08Z",
      commitCount: 3,
      technologyStack: ["Scala", "libGDX"],
      links: [],
    },
    {
      name: "scala-fizzbuzz",
      url: "https://github.com/5000164/scala-fizzbuzz",
      description: "Scala 練習用リポジトリ",
      comment: `
        FizzBuzz で Scala の練習をした。
      `,
      firstCommitDate: "2017-01-30T17:14:28Z",
      lastCommitDate: "2017-02-01T19:09:05Z",
      commitCount: 20,
      technologyStack: ["Scala"],
      links: [],
    },
    {
      name: "go-file-rename",
      url: "https://github.com/5000164/go-file-rename",
      description: "Go 練習用リポジトリ",
      comment: `
        ファイル名を変更する処理で Go の練習をした。
      `,
      firstCommitDate: "2015-02-12T16:31:55Z",
      lastCommitDate: "2015-02-12T16:43:06Z",
      commitCount: 2,
      technologyStack: ["Go"],
      links: [],
    },
    {
      name: "fuelphp-test-practice-1",
      url: "https://github.com/5000164/fuelphp-test-practice-1",
      description: "FuelPHP 練習用リポジトリ",
      comment: `
        FuelPHP のテストについて練習した。
      `,
      firstCommitDate: "2014-12-04T17:03:26Z",
      lastCommitDate: "2014-12-10T17:12:40Z",
      commitCount: 2,
      technologyStack: ["PHP", "FuelPHP"],
      links: [],
    },
    {
      name: "processing-practice-2",
      url: "https://github.com/5000164/processing-practice-2",
      description: "Processing 練習用リポジトリ",
      comment: `
        Processing の練習をした。
      `,
      firstCommitDate: "2014-11-12T16:01:27Z",
      lastCommitDate: "2014-11-18T18:04:03Z",
      commitCount: 2,
      technologyStack: ["Processing"],
      links: [],
    },
    {
      name: "processing-practice-1",
      url: "https://github.com/5000164/processing-practice-1",
      description: "Processing 練習用リポジトリ",
      comment: `
        Processing の練習をした。
      `,
      firstCommitDate: "2014-11-11T19:39:31Z",
      lastCommitDate: "2014-11-12T15:33:44Z",
      commitCount: 3,
      technologyStack: ["Processing"],
      links: [],
    },
    {
      name: "surfaceview-loading",
      url: "https://github.com/5000164/surfaceview-loading",
      description: "Android 練習用リポジトリ",
      comment: `
        Android でのアニメーションの表示を練習した。
      `,
      firstCommitDate: "2014-07-22T15:33:42Z",
      lastCommitDate: "2014-07-22T15:50:42Z",
      commitCount: 2,
      technologyStack: ["Android", "Java"],
      links: [],
    },
    {
      name: "PPFF",
      url: "https://github.com/5000164/PPFF",
      description: "PHP 練習用リポジトリ",
      comment: `
        フレームワークの仕組みを理解するため。
      `,
      firstCommitDate: "2014-03-25T15:17:32Z",
      lastCommitDate: "2014-04-15T16:45:02Z",
      commitCount: 4,
      technologyStack: ["PHP"],
      links: [],
    },
    {
      name: "dxlib-practice-4",
      url: "https://github.com/5000164/dxlib-practice-4",
      description: "DX Library 練習用リポジトリ",
      comment: `
        DX Library の練習をした。
      `,
      firstCommitDate: "2014-03-18T10:14:03Z",
      lastCommitDate: "2014-03-31T14:12:14Z",
      commitCount: 4,
      technologyStack: ["C++", "DX Library"],
      links: [],
    },
    {
      name: "dxlib-practice-3",
      url: "https://github.com/5000164/dxlib-practice-3",
      description: "DX Library 練習用リポジトリ",
      comment: `
        DX Library の練習をした。
      `,
      firstCommitDate: "2014-03-14T05:45:12Z",
      lastCommitDate: "2014-03-18T08:15:46Z",
      commitCount: 57,
      technologyStack: ["C++", "DX Library"],
      links: [],
    },
    {
      name: "dxlib-practice-1",
      url: "https://github.com/5000164/dxlib-practice-1",
      description: "DX Library 練習用リポジトリ",
      comment: `
        DX Library の練習をした。
      `,
      firstCommitDate: "2014-03-13T15:14:53Z",
      lastCommitDate: "2014-03-15T09:55:15Z",
      commitCount: 7,
      technologyStack: ["C++", "DX Library"],
      links: ["https://blog.5000164.jp/2014/3/16/dxlib-practice-1/"],
    },
    {
      name: "dxlib-practice-2",
      url: "https://github.com/5000164/dxlib-practice-2",
      description: "DX Library 練習用リポジトリ",
      comment: `
        DX Library の練習をした。
      `,
      firstCommitDate: "2014-03-13T16:00:11Z",
      lastCommitDate: "2014-03-15T09:16:30Z",
      commitCount: 8,
      technologyStack: ["C++", "DX Library"],
      links: ["https://blog.5000164.jp/2014/4/1/dxlib-practice-2/"],
    },
    {
      name: "wpf-practice-2",
      url: "https://github.com/5000164/wpf-practice-2",
      description: "WPF 練習用リポジトリ",
      comment: `
        WPF の練習をした。
      `,
      firstCommitDate: "2014-03-11T12:36:06Z",
      lastCommitDate: "2014-03-13T15:31:49Z",
      commitCount: 4,
      technologyStack: ["C#", "WPF"],
      links: ["https://blog.5000164.jp/2014/3/13/wpf-practice-2/"],
    },
    {
      name: "wpf-practice-1",
      url: "https://github.com/5000164/wpf-practice-1",
      description: "WPF 練習用リポジトリ",
      comment: `
        WPF の練習をした。
      `,
      firstCommitDate: "2014-03-11T12:33:25Z",
      lastCommitDate: "2014-03-13T15:28:55Z",
      commitCount: 4,
      technologyStack: ["C#", "WPF"],
      links: ["https://blog.5000164.jp/2014/3/11/wpf-practice-1/"],
    },
  ],
}
